import React from "react";
import styles from "./HomeSection.module.scss";
import HomeSectionTitle from "./HomeSectionTitle";

const HomeSection = ({
  className = "",
  rootClassName = "",
  titleMeta,
  children,
}) => {
  return (
    <section className={`series ${styles["home-section"]} ${rootClassName}`}>
      <div className={styles["home-section__title"]}>
        <HomeSectionTitle {...titleMeta} />
      </div>
      <div className={`${styles["home-section__gutter"]} ${className}`}>
        {children}
      </div>
    </section>
  );
};

export default HomeSection;
