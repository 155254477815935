import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { createPortal } from 'react-dom';
import Alert from "Components/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useAlert = () => {
  const [messages, setMessages] = useState([]);
  const warrperNodeRef = useRef(null);

  const open = ({
    type = '',
    message = '',
    duration = 3
  }) => {
    const timerId = setTimeout(() => cleanMessage(timerId), duration * 1000);
    setMessages(l => ([...l, {
      type,
      message,
      timerId
    }]))
  }

  const cleanMessage = (timerId) => setMessages(l => l.filter(i => i.timerId !== timerId));

  const api = new Proxy({
    open
  }, {
    get (target, prop) {
      if (prop != 'open') {
        return (params) => target.open({
          ...params,
          type: prop
        })
      }
      return target.open
    },
  });

  const getIcon = useCallback((type) => {
    switch (type) {
      case 'success': return <FontAwesomeIcon icon="fa-solid fa-circle-check" style={{ color: '#06f516' }} />;
      case 'error': return <FontAwesomeIcon icon="fa-solid fa-circle-xmark" style={{ color: '#c53929' }} />;
      default: return null;
    }
  }, []);

  useEffect(() => {
    warrperNodeRef.current = document.querySelector('.global__alert-warrper__list');
  }, [])

  const context = useMemo(() => warrperNodeRef.current ?
    createPortal(
      <>
        {
          messages.map(m =>
            <li key={m.timerId}>
              <Alert
                id={m.timerId}
                icon={m?.icon ?? getIcon(m.type)}
                type={m.type}
                message={m.message}
              />
            </li>,
          )}
      </>,
      warrperNodeRef.current
    ) : null, [
    messages,
    window.document
  ]);

  return [api, context];
};

export default useAlert;
