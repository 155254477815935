import React, { useCallback, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useUserState } from "Store/user";
import { useGlobalState } from "Store/global";
import styles from "./index.module.scss";

const vadate = {
  name: (v) => v?.length > 0,
  email: (v) => v?.length > 0,
  password: (v) => v?.length > 0,
};

const Login = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [isDisplayContent, setIsDisplayContent] = useState(false);
  const [isLoginFaild, setIsLoginFaild] = useState(false);
  const [formVadateError, setFormVadateError] = useState({});
  const [_, setStoreUser] = useUserState();
  const [globalState] = useGlobalState();
  const navigator = useNavigate();
  const params = useRef({});
  const buttonRef = useRef();

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const vadateeError = vadateParams([
      ...(isRegister ? ["name"] : []),
      "email",
      "password",
    ]);
    if (vadateeError) {
      return;
    }
    const url = isRegister ? "/user/register" : "/user/login";
    const { fetch, triggleLoadingMask } = globalState;
    triggleLoadingMask();
    if (fetch) {
      const res = await fetch.post(url, params.current);
      if (res?.success) {
        setStoreUser({
          loading: false,
          user: res.data,
        });
        navigator("/");
      } else {
        setIsLoginFaild(true);
      }
    }
    triggleLoadingMask();
  };

  const blurInput = (e, key) => {
    const value = e.target.value;
    params.current[key] = value;
    vadateParams([key]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      buttonRef.current.focus();
      buttonRef.current.click();
    }
  };

  const vadateParams = useCallback(
    (keys) => {
      const allKeys = Object.keys(vadate);
      const targetKeys = keys
        ? keys.filter((k) => allKeys.includes(k))
        : allKeys;
      const newError = {};
      targetKeys.forEach((k) => {
        newError[k] = !vadate[k](params.current[k]);
      });
      setFormVadateError((v) => ({
        ...v,
        ...newError,
      }));
      return Object.values(newError).includes(true);
    },
    [params.current]
  );

  const togglePasswd = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDisplayContent((v) => !v);
  }, []);

  const toggleRegister = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRegister((v) => !v);
  }, []);

  return (
    <div className={`${styles["login"]} page`}>
      <main className={styles["login-main"]}>
        <h3 className={styles["login-main__title"]}>Welcome Back</h3>
        <p
          className={`${styles["login-main__text"]} ${
            isLoginFaild ? styles["error"] : ""
          }`}
        >
          {isLoginFaild
            ? "Please check your login and password"
            : "Sign in with your email address and your password"}
        </p>
        <form action="" className={styles["login-main__form"]}>
          {isRegister && (
            <label
              className={`${styles["login-main__form-input"]} ${
                formVadateError?.name ? styles["error"] : ""
              }`}
            >
              <span>Name*</span>
              <input
                id="email"
                type="text"
                onBlur={(e) => blurInput(e, "name")}
              />
            </label>
          )}

          <label
            className={`${styles["login-main__form-input"]} ${
              formVadateError?.email ? styles["error"] : ""
            }`}
          >
            <span>Email*</span>
            <input
              id="email"
              type="text"
              onBlur={(e) => blurInput(e, "email")}
            />
          </label>

          <label
            className={`${styles["login-main__form-input"]} ${
              formVadateError?.password ? styles["error"] : ""
            }`}
          >
            <span>Password*</span>
            <input
              id="password"
              type={isDisplayContent ? "text" : "password"}
              onBlur={(e) => blurInput(e, "password")}
              onKeyDown={handleKeyDown}
            />
            <button
              className={styles["login-main__form-input__suffix"]}
              onClick={togglePasswd}
            >
              <FontAwesomeIcon
                icon={[
                  "fa-regular",
                  `fa-eye${isDisplayContent ? "" : "-slash"}`,
                ]}
              />
            </button>
          </label>
          <div className={styles["login-main__form-register"]}>
            <button
              onClick={submit}
              ref={buttonRef}
              className={styles["login-main__form-submit"]}
            >
              Sign in
            </button>
            {!isRegister && (
              <p className={styles["login-main__form-register__text"]}>
                Don't have a MyLV account?
              </p>
            )}
            <button
              onClick={toggleRegister}
              className={styles["login-main__form-register__create-account"]}
            >
              {isRegister ? "Back to login" : "Create yours now"}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Login;
