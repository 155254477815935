import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "../page/Home/index";
import AppPage from "../component/AppPage";
import PageContent from "../component/PageContent";
import Login from "../page/Login/index";
import Product from "../page/Product/index";
import Profile from "../page/Profile/index";
import Favorite from "../page/Favorite/index";
import Manager from "../page/Manager/index";
import Categoryies from "../page/Categoryies/index";

export const routes = [
  {
    path: "/",
    element: <AppPage />,
    children: [
      {
        path: "product/:id",
        element: <Product />,
      },
      {
        path: "filter/:primary/:secondary?/:tertiary?",
        element: <Categoryies />,
      },
      {
        path: "/profile",
        element: <PageContent auth />,
        children: [
          {
            path: "favorite",
            name: "favorite",
            element: <Favorite />,
          },
          {
            path: "manager",
            name: "manager",
            element: <Manager />,
          },
          {
            path: "",
            name: "profile",
            element: <Favorite />,
          },
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
