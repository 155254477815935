import React from "react";
import Banner from "./Banner";
import Categorys from "./Categorys";
import Serverice from "./Serverice";
import Recommended from "./Recommended";
import RecommendedCufflinks from "./RecommendedCufflinks";
import PageContainer from "../../component/PageContainer";
import styles from "./index.module.scss";

function Home() {
  return (
    <PageContainer className={styles["home"]}>
      <Banner />
      <Categorys />
      <Recommended />
      <RecommendedCufflinks />
      <Serverice />
    </PageContainer>
  );
}

export default Home;
