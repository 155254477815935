import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageContainer from "../../component/PageContainer";
import styles from "./Product.module.scss";

function NotFound(props) {
  return <p>Opps! Not Found!!</p>;
}

export default NotFound;
