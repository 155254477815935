import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserState } from "Store/user";
import { getToken } from "@/common/fetch";

const PageContent = ({ auth, adminAuth }) => {
  const [storeUser] = useUserState();
  const token = useMemo(() => getToken() ?? "", []);
  const user = useMemo(() => storeUser.user, [storeUser.user]);
  const isAdmin = useMemo(() => storeUser?.user?.admin, [storeUser.user]);
  const loadingUser = useMemo(() => storeUser.loading, [storeUser.loading]);

  if (auth || adminAuth) {
    if (loadingUser && token.length > 0) {
      return <div style={{ flex: 1 }}></div>;
    } else if (!user) {
      return <Navigate to="/login" replace />;
    }
  }
  return <Outlet />;
};

export default PageContent;
