import React from "react";
import styles from "./AlertContainer.scss";
import Alert from './Alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AlertContainer () {
  return (
    <div className="global__alert-warrper">
      <ul className="global__alert-warrper__list">
      </ul>
    </div>
  );
}

export default AlertContainer;
