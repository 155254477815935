import { atom, useSetRecoilState, useRecoilValue } from "recoil";
import { getToken } from "@/common/fetch";

export const userState = atom({
  key: "userState",
  default: {
    loading: true,
    user: null,
  },
});

export const useUserState = () => [
  useRecoilValue(userState),
  useSetRecoilState(userState),
];
