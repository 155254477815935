import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalState } from "Store/global";
import styles from "./SliderBar.module.scss";

function SliderBar({ isOpen, setIsOpen }) {
  const [displayList, setDisplayList] = useState([]);
  const [activeIndex, setActiveIndex] = useState([]);
  const [data, setData] = useState([]);
  const [{ fetch }] = useGlobalState();

  const selectItem = (levelIndex, index, item) => {
    let newIndexs = activeIndex.slice(0, levelIndex + 1);
    newIndexs[levelIndex] = newIndexs[levelIndex] !== index ? index : -1;
    newIndexs = newIndexs.filter((i) => i > -1);
    setActiveIndex(newIndexs);
    let newList = displayList.slice(0, newIndexs.length + 1);
    let isCancel = false;
    if (newIndexs?.[levelIndex] > -1) {
      newList[levelIndex + 1] = item.children;
    } else {
      isCancel = true;
    }
    newList = newList.filter((arr) => arr.length > 0);
    setDisplayList(newList);
    if (item.children <= 0 && !isCancel) {
      const filters = newList.map((list, i) => list[newIndexs[i]]?.id);
      setIsOpen(false);
    }
  };

  const fetchSliderBarList = async () => {
    if (fetch) {
      const res = await fetch.get(`/product/sliderbar`);
      if (res?.success) {
        setData(res?.data);
      }
    }
  };

  useEffect(() => {
    fetchSliderBarList();
  }, []);

  useEffect(() => {
    setDisplayList([data]);
    setActiveIndex([]);
  }, [isOpen]);

  return (
    <div
      className={`${styles["slider-bar"]} ${isOpen ? styles["display"] : ""}`}
    >
      {displayList.map((sublist, levelIndex) => (
        <div
          className={styles["slider-bar__warrper"]}
          key={levelIndex.toString()}
        >
          <ul className={styles["slider-bar__warrper-menu"]}>
            {sublist.map((item, index) => (
              <li
                key={item.id}
                className={`${styles["slider-bar__warrper-menu__item"]} ${
                  activeIndex[levelIndex] === index ? styles["active"] : ""
                }`}
              >
                {item?.children?.length > 0 ? (
                  <button
                    className={styles["slider-bar__warrper-menu__item-button"]}
                    onClick={() => selectItem(levelIndex, index, item)}
                  >
                    <span>{item.name}</span>
                    <FontAwesomeIcon
                      icon="fa-solid fa-chevron-right"
                      className={
                        styles["slider-bar__warrper-menu__item-button__icon"]
                      }
                    />
                  </button>
                ) : (
                  <Link
                    to={`/filter/${item?.filter?.split(",")?.join("/")}`}
                    className={styles["slider-bar__warrper-menu__item-button"]}
                    onClick={() => selectItem(levelIndex, index, item)}
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          {/* {levelIndex == 0 && (
            <ul className={styles["slider-bar__warrper-service"]}>
              <li className={styles["slider-bar__warrper-service__item"]}>
                <a href="#">环保与可持续性</a>
              </li>
              <li className={styles["slider-bar__warrper-service__item"]}>
                <a href="#">寻找一家专卖店</a>
              </li>
              <li className={styles["slider-bar__warrper-service__item"]}>
                <a href="#">配送至： 中国大陆</a>
              </li>
              <li className={styles["slider-bar__warrper-service__item"]}>
                <a href="#">需要帮助?</a>
              </li>
              <li className={styles["slider-bar__warrper-service__item"]}>
                <a href="tel:400 6588 555">400 6588 555</a>
              </li>
            </ul>
          )} */}
        </div>
      ))}
    </div>
  );
}

export default SliderBar;
