import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import HomeSection from "../../component/HomeSection";
import HomeCategoryList from "../../component/HomeCategoryList";
import { getBucketPath } from "../../common/utils";
import styles from "./index.module.scss";

const defaultCategorys = [
  ["Women's Bags", '1,5'],
  ["Women's Small Leather Goods", '1,9'],
  ["Women's Shoes", '1,8'],
  ["Women's Fashion Jewelry", '1,7'],
  ["Men's Shoes", '2,8'],
  ["Men's Belts", '2,10'],
  ["Men's Bags", '2,5'],
  ["Men's Watch", '2,6'],
].map(([d, filter], i) => ({
  name: d,
  cover: getBucketPath(`/Web/categorys/${i + 1}.jpg`),
  id: i.toString(),
  link: `/filter/${filter.split(",").join("/")}`
}));

function Categorys () {
  const [categorys, setCategorys] = useState([]);

  const getCategorys = () => {
    setCategorys(defaultCategorys);
  };

  useEffect(() => {
    getCategorys();
  }, []);

  return (
    <HomeSection
      titleMeta={{ title: "Explore a Selection of the Maison's Creations" }}
      rootClassName={styles["category"]}
    >
      <HomeCategoryList list={categorys} />
    </HomeSection>
  );
}

export default Categorys;
