import React, { useEffect, useMemo } from "react";
import { useHref } from "react-router";
import ProfileSide from "./ProfileSide";
import Breadcrumb from "./Breadcrumb";
import styles from "./ProfilePageContent.module.scss";
import { Link } from "react-router-dom";
import { useUserState } from "Store/user";

const ProfilePageContent = ({ children }) => {
  const href = useHref();
  const navName = useMemo(() => href.slice(1).split("/"), [href]);

  const [storeUser] = useUserState();
  const isAdmin = useMemo(
    () => storeUser?.user?.type == "admin",
    [storeUser.user]
  );

  return (
    <div className={styles["profile-page"]}>
      <div className={styles["profile-page__header"]}>
        <ul className={styles["profile-page__header__nav"]}>
          <li className={styles["profile-page__header__nav-item"]}>
            <Link to="/profile/favorite">Collections</Link>
          </li>
          {isAdmin && (
            <li className={styles["profile-page__header__nav-item"]}>
              <Link to="/profile/manager">Manager</Link>
            </li>
          )}
        </ul>
      </div>
      {/* <div className={styles["profile-page__side"]}>
        <ProfileSide />
      </div> */}
      <div className={styles["profile-page__content"]}>{children}</div>
    </div>
  );
};

export default ProfilePageContent;
