import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getBucketPath } from "../../common/utils";
import HomeSection from "../../component/HomeSection";
import HomePeoductList from "../../component/HomePeoductList";

const defaultList = [
  [
    247,
    "LV Trainer Sneaker",
    `https://cynsrchic.com/bucket/MENSHOESLV/LV%20Trainer%20Sneaker1A8WAX/louis-vuitton-lv-trainer-sneaker--BL9U6PMI01_PM2_Front%20view.png`,
    "160",
  ],
  [
    250,
    "LV Trainer Sneaker",
    `https://cynsrchic.com/bucket/MENSHOESLV/LV%20Trainer%20Sneaker1ACWBG/louis-vuitton-lv-trainer-sneaker--BR9U3PNU52_PM2_Front%20view.png`,
    "160",
  ],
  [
    252,
    "LV Trainer Sneaker",
    `https://cynsrchic.com/bucket/MENSHOESLV/LV%20Trainer%20Sneaker1AD6DK/louis-vuitton-lv-trainer-sneaker--BR9U8PDN20_PM2_Front%20view.png`,
    "160",
  ],
  [
    253,
    "LV Trainer",
    `https://cynsrchic.com/bucket/MENSHOESLV/LV%20TRAINER1A9JG7/1.png`,
    "150",
  ],
].map(([id, name, cover, price]) => ({
  id,
  name,
  cover,
  link: "",
  price,
}));

const defaultBanner = getBucketPath("/Web/banner/3.jpg");

function RecommendedCufflinks() {
  const [product, setProduct] = useState([]);

  const fetchData = () => {
    setProduct(defaultList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HomeSection
      titleMeta={{
        overline: "Men",
        title: "New In: Trainer Sneaker",
        ctas: "Shop the Collection",
      }}
      rootClassName={styles["recommended"]}
      className={styles["recommended-wrapper"]}
    >
      <div className={styles["recommended-wrapper__cover"]}>
        <img src={defaultBanner} alt="" />
      </div>
      <div className={styles["recommended-wrapper__product"]}>
        <HomePeoductList list={product} />
      </div>
    </HomeSection>
  );
}

export default RecommendedCufflinks;
