import React from "react";
import { useGlobalState } from "Store/global";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserState } from "Store/user";
import styles from "./HomePeoductList.module.scss";

function HomePeoductList({ list = [], setList }) {
  const [{ fetch }] = useGlobalState();
  const [storeUser] = useUserState();

  const clickFavorite = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    if (storeUser) {
      const callFunc = item.isFavorite ? fetch.delete : fetch.post;
      const res = await callFunc(`/product/favorite`, { id: item.id });
      if (res.success) {
        if (setList) {
          setList((v) => {
            const target = v.find((i) => i.id === item.id);
            if (target) {
              target.isFavorite = !item.isFavorite;
            }
            return [...v];
          });
        }
      }
    }
  };

  return (
    <ul className={styles["product__list"]}>
      {list.map((item) => (
        <li key={item.id} className={styles["product__list-item"]}>
          <div className={styles["product__list-item__cover"]}>
            <img src={item.cover ?? item?.imagePaths?.[0]} />
          </div>
          <div className={styles["product__list-item__name"]}>
            <Link to={`/product/${item.id}`}>{item.name}</Link>
          </div>
          <div className={styles["product__list-item__collection"]}>
            <button
              onClick={(e) => clickFavorite(e, item)}
              className={`${styles["product__list-item__collection-button"]} ${
                item?.isFavorite ? styles["favorite"] : ""
              }`}
            >
              <FontAwesomeIcon
                icon={`fa-${item?.isFavorite ? "solid" : "regular"} fa-heart`}
              />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default HomePeoductList;
