import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useGlobalTool } from "Store/global";
import useAlert from 'Hooks/useAlert';

const Profile = () => {
  const [productId, setProductId] = useState("484");
  const [path, setPath] = useState("/testing");
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [api, context] = useAlert();
  const fetch = useGlobalTool("fetch");

  const selectFile = (e) => {
    setFiles(e.target.files);
  };
  const selectProductId = (e) => {
    setProductId(e.target.value.trim());
  };
  const setUploadPath = (e) => {
    setPath(e.target.value.trim());
  };

  async function upload (e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    const res = await fetch.fpost(
      "/file-manager/upload",
      {
        productId,
        path,
        "files[]": files,
      },
      {
        method: "put",
      }
    );
    if (res.success) {
      api.success({ message: '上传成功' });
    } else {
      api.error({ message: '上传失败' });
    }
    setIsLoading(false);
  }

  return (
    <div className={`${styles["manager"]} page`}>
      {context}
      <h3>图片上传</h3>
      <form className={styles["manager-form"]}>
        <label className={styles["manager-form__item"]}>
          <span className={styles["manager-form__item__label"]}>商品id:</span>
          <input
            type="text"
            name="productId"
            onBlur={selectProductId}
          />
        </label>
        <label className={styles["manager-form__item"]}>
          <span className={styles["manager-form__item__label"]}>上传路径:</span>
          <input type="text" name="path" onBlur={setUploadPath} />
        </label>
        <label className={styles["manager-form__item"]}>
          <span className={styles["manager-form__item__label"]}>图片选择:</span>
          <input
            type="file"
            name="files"
            id="files"
            className={styles["manager-form__item__file-input"]}
            multiple
            onChange={selectFile}
          />
        </label>
        <button
          type="submit"
          disabled={isLoading}
          className={styles["manager-form__upload"]}
          onClick={upload}
        >
          上传
        </button>
      </form>
    </div>
  );
};

export default Profile;
