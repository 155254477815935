import React from "react";
import styles from "./HomeItemPreviewList.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function HomeItemPreviewList({ list = [] }) {
  return (
    <ul className={styles["preview__list"]}>
      {list.map((item, index) => (
        <li key={index.toString()} className={styles["preview__list-item"]}>
          <div className={styles["preview__list-item__warrper"]}>
            <div className={styles["warrper"]}>
              <ul className={styles["warrper-list"]} style={{ width: "100%" }}>
                <li className={styles["warrper-list__item"]}>
                  <img src={item?.cover ?? item?.imagePaths?.[0]} />
                </li>
              </ul>
            </div>
            <div className={styles["action"]} style={{ display: "none" }}>
              <button className={styles["action-item"]}>
                <FontAwesomeIcon icon="fas fa-angle-left" />
              </button>
              <button className={styles["action-item"]}>
                <FontAwesomeIcon icon="fas fa-angle-right" />
              </button>
            </div>
          </div>

          <div className={styles["preview__list-item__info"]}>
            <div className={styles["preview__list-item__info-name"]}>
              <Link to={`/product/${item.id}`}>{item.name}</Link>
            </div>
            <div className={styles["preview__list-item__info-price"]}>
              ${item.price}
            </div>
          </div>

          <div className={styles["preview__list-item__collection"]}>
            <button className={styles["preview__list-item__collection-button"]}>
              <FontAwesomeIcon
                className={styles["icon"]}
                icon="fa-regular fa-heart"
              />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default HomeItemPreviewList;
