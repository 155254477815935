import React, { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "Store/global";
import PageContainer from "../../component/PageContainer";
import styles from "./index.module.scss";
import HomeItemPreviewList from "../../component/HomeItemPreviewList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const pageSize = 12;

const Categoryies = () => {
  const { primary = "", secondary = "", tertiary = "" } = useParams();
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [{ fetch }] = useGlobalState();
  const observerRef = useRef(null);
  const pageRef = useRef(1);

  const fetchProductList = async () => {
    if (fetch && !isLoading) {
      setIsLoading(true);
      const filters = [primary, secondary, tertiary].join(",");
      const res = await fetch.get(
        `/product?page=${pageRef.current}&pageSize=${pageSize}&keys=${filters}`
      );
      if (res?.success) {
        setProduct((v) => [...v, ...res?.data]);
        pageRef.current += 1;
      }
      setIsLoading(false);
    }
  };

  const createObserver = useCallback(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchProductList();
          observer.unobserve(entry.target);
        }
      });
    });
    observerRef.current = observer;
  }, []);

  const startObserver = () => {
    const list = document.querySelector("#product-list>ul").children;
    if (list?.length > 2 && (pageRef.current - 1) * pageSize <= list.length) {
      observerRef.current.observe(list[list.length - 4]);
    }
  };

  useEffect(() => {
    fetchProductList();
    createObserver();
    return () => {
      pageRef.current = 1;
      setProduct([]);
    };
  }, []);

  useEffect(() => {
    if (product.length > 0) {
      startObserver();
    }
  }, [product]);

  return (
    <PageContainer className={styles["categoryies"]}>
      {/* <div className={styles["categoryies__header"]}>
        <img src="" alt="" />

        <h3 className={styles["categoryies__header-title"]}>
          Men's Pre-Fall 2024
        </h3>
      </div> */}
      <div id="product-list" className={styles["categoryies__list"]}>
        <HomeItemPreviewList list={product} />
      </div>
      {isLoading && (
        <div className={styles["categoryies__loading"]}>
          <FontAwesomeIcon icon="fa-solid fa-spinner" spin size="3x" />
        </div>
      )}
    </PageContainer>
  );
};

export default Categoryies;
