import React from "react";
import styles from "./Alert.module.scss";

function Alert ({ id, message = '', icon, type }) {
  return (
    <div id={id} className={`${styles["alert"]}`}>
      {icon && <span className={`${styles["alert-icon"]} ${styles[type]}`}>{icon}</span>}
      <span className={`${styles["alert-message"]}`}>{message}</span>
    </div>
  );
}

export default Alert;
