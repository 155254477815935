import React from "react";
import styles from "./HomeSectionTitle.module.scss";

function HomeSectionTitle({
  title,
  overline,
  ctas,
  link,
  isMaskLink,
  className = "",
}) {
  return (
    <div className={`${styles["home-section-title"]} ${className}`}>
      {overline ? (
        <span className={styles["home-section-title__overline"]}>
          {overline}
        </span>
      ) : null}
      <h3 className={styles["home-section-title__title"]}>{title}</h3>
      {link ? (
        <a
          className={`${styles["home-section-title__ctas"]} ${styles["link"]} ${
            isMaskLink ? styles["mask"] : ""
          }`}
          href={link}
        >
          {ctas}
        </a>
      ) : (
        <span className={`${styles["home-section-title__ctas"]}`}>{ctas}</span>
      )}
    </div>
  );
}

export default HomeSectionTitle;
