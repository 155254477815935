import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfilePageContent from "Components/ProfilePageContent";
import { useGlobalTool } from "Store/global";
import styles from "./index.module.scss";
import HomePeoductList from "../../component/HomePeoductList";

const pageSize = 16;

const Favorite = () => {
  const [favoriteList, setFavoriteList] = useState([]);
  const fetch = useGlobalTool("fetch");
  const [isLoading, setIsLoading] = useState(false);
  const observerRef = useRef(null);
  const watchRef = useRef(null);
  const pageRef = useRef(1);

  const fetchLength = () => (pageRef.current - 1) * pageSize;
  const getIsFinished = () => fetchLength() != favoriteList.length;

  const getFavoriteList = async () => {
    if (!isLoading && !getIsFinished()) {
      setIsLoading(true);
      const res = await fetch.get(
        `/product/favorite?page=${pageRef.current}&pageSize=${pageSize}`
      );
      if (res.success) {
        setFavoriteList((v) => [...v, ...(res?.data ?? [])]);
        pageRef.current += 1;
      }
      setIsLoading(false);
    }
  };

  const createObserver = useCallback(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          getFavoriteList();
          observer.unobserve(entry.target);
          watchRef.current = null;
        }
      });
    });
    observerRef.current = observer;
  }, [favoriteList.length]);

  const startObserver = () => {
    const domList = document.querySelector("#favorite>ul").children;
    if (!getIsFinished()) {
      watchRef.current = domList[domList.length - 4];
      observerRef.current.observe(domList[domList.length - 4]);
    }
  };

  useEffect(() => {
    getFavoriteList();
    return () => {
      pageRef.current = 1;
      setFavoriteList([]);
    };
  }, []);

  useEffect(() => {
    createObserver();
    if (favoriteList.length > 0) {
      startObserver();
    }
    return () => {
      if (watchRef.current) {
        observerRef.current.unobserve(watchRef.current);
      }
    };
  }, [favoriteList]);

  return (
    <ProfilePageContent>
      <div id="favorite" className={styles["favorite"]}>
        <HomePeoductList list={favoriteList} setList={setFavoriteList} />
      </div>
      {isLoading && (
        <div className={styles["favorite__loading"]}>
          <FontAwesomeIcon icon="fa-solid fa-spinner" spin size="3x" />
        </div>
      )}
    </ProfilePageContent>
  );
};

export default Favorite;
