class Fetch {
  constructor(config = {}) {
    const origin = window.location.origin;
    this.baseUrl = `${origin}/api` || process.env.REACT_APP_API_ADDRESS;
    // this.config = config;
  }

  _fetch(url = "", params = null, config = {}) {
    const token = getToken();
    const init = {
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      ...config,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Charset": "utf-8",
        ...(config.isFrom ? null : { "Content-Type": "application/json" }),
        ...(token ? { Authorization: `Bearer ${token}` } : null),
        ...config.headers,
      },
      method: config.method.toUpperCase(),
    };
    if (init.method === "GET") {
      // delete init.body;
    } else if (params) {
      console.log(config.isFrom);
      init.body = config.isFrom ? params : JSON.stringify(params);
    }

    return window
      .fetch(`${this.baseUrl}${url}`, init)
      .then(async (res) => {
        const contentType = res.headers.get("Content-Type");
        if (contentType) {
          if (contentType.includes("application/json")) {
            return res.json();
          } else if (contentType.includes("text/")) {
            return res.text();
          }
        } else {
          throw new Error("Unsupported content type");
        }
        if (res.status === 401) {
          clearToken();
          throw new Error("not auth");
          // TODO Navgate To Login
        }
        if (res.status !== 200) {
          throw new Error(resData);
        }
      })
      .then((resData) => {
        return {
          success: true,
          ...(resData?.success || resData?.data ? resData : { data: resData }),
        };
      })
      .catch((err) => {
        console.log(err);
        return {
          success: false,
          message: err.message,
        };
      });
  }

  _crateMethodsFactory(method) {
    return (url = "", params = null, config = {}) =>
      this._fetch(url, params, {
        ...config,
        method,
      });
  }

  get = (url = "", params = null, config = {}) => {
    return this._fetch(url, params, {
      ...config,
      method: "get",
    });
  };

  post = (url = "", params = null, config = {}) => {
    return this._fetch(url, params, {
      ...config,
      method: "post",
    });
  };

  delete = (url = "", params = null, config = {}) => {
    return this._fetch(url, params, {
      ...config,
      method: "delete",
    });
  };

  put = (url = "", params = null, config = {}) => {
    return this._fetch(url, params, {
      ...config,
      method: "put",
    });
  };

  fpost(url = "", params = null, config = {}) {
    const data = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (key === "files[]") {
        Object.keys(value).forEach((k, index) => {
          data.append(`files[]`, value[k], value[k]?.name);
        });
      } else {
        data.append(key, value);
      }
    });
    return this._fetch(url, data, {
      method: "post",
      ...config,
      headers: {
        ...config.headers,
      },
      isFrom: true,
    });
  }
}

export const clearToken = () => {
  document.cookie = "token = ;";
  document.cookie = "refresh_token = ;";
};

export const getToken = () =>
  document.cookie.match(/(?:token=)([\w\.]+)(?:\;|$)/)?.[1] ?? "";

const fetch = new Fetch();

export default fetch;
