import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import styles from "./index.module.scss";
import { getBucketPath } from "../../common/utils";
import HomeSectionTitle from "../../component/HomeSectionTitle";

const defaultTimeout = 4000;

const staticBannerList = [
  {
    cover: getBucketPath("/Web/banner/1.jpg"),
    title: "Summer-Fall 2024",
    overline: "MEN",
    ctas: "Discover the Collection",
    link: "/",
  },
];

function Banner() {
  const [banners, setBaners] = useState(() => []);
  const isCanRun = useMemo(() => banners.length > 1, [banners]);
  const [activeIndex, setActiveIndex] = useState(0);
  const nextActiveIndex = useCallback(
    (i) => (i + 1) % (banners.length + 1),
    [banners]
  );
  const [isReset, setIsReset] = useState(false);
  const timeoutRef = useRef(null);

  const changeIndex = (i) => {
    setActiveIndex(i);
    runTimeout(defaultTimeout);
  };

  const stopTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const runTimeout = (defaultTimeout) => {
    if (!isCanRun) return;
    stopTimeout();
    timeoutRef.current = setTimeout(() => {
      setActiveIndex((i) => nextActiveIndex(i));
      runTimeout(defaultTimeout);
    }, defaultTimeout);
  };

  const resetActiveIndex = () => {
    if (!isCanRun) return;
    if (activeIndex === banners.length) {
      setIsReset(true);
      setActiveIndex(0);
      runTimeout(defaultTimeout - 1000);
      setTimeout(() => {
        setIsReset(false);
      }, 500);
    }
  };

  const fetchBannerList = () => {
    setBaners(staticBannerList);
    if (isCanRun) {
      runTimeout(defaultTimeout);
    }
  };

  useEffect(() => {
    fetchBannerList();
  }, []);

  return (
    <section className={styles["home-section"]}>
      <div
        className={styles["banner"]}
        onMouseEnter={stopTimeout}
        onMouseLeave={() => runTimeout(defaultTimeout)}
      >
        <div
          className={`${styles["banner-wapper"]} ${
            isReset ? styles["static"] : ""
          }`}
          style={{
            left: `${activeIndex * -100}%`,
          }}
          onTransitionEnd={resetActiveIndex}
        >
          {(banners.length > 0 ? [...banners, banners[0]] : []).map(
            (banner, index) => (
              <div
                className={styles["banner-wapper__item"]}
                key={index.toString()}
              >
                <img src={banner?.cover} alt="" />
                <HomeSectionTitle
                  {...banner}
                  isMaskLink
                  className={styles["banner-wapper__item-title"]}
                />
              </div>
            )
          )}
        </div>
        {isCanRun ? (
          <>
            <button
              className={styles["pre"]}
              onClick={() => changeIndex(activeIndex - 1)}
            />
            <button
              className={styles["next"]}
              onClick={() => changeIndex(activeIndex + 1)}
            />
          </>
        ) : null}
      </div>
    </section>
  );
}

export default Banner;
