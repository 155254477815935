import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./LoadingMask.module.scss";

const LoadingMask = () => {
  return (
    <div className={styles["loading"]}>
      <FontAwesomeIcon icon="fa-solid fa-spinner" spin size="3x" />
    </div>
  );
};

export default LoadingMask;
