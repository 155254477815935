import React from "react";
import styles from "./Logo.module.scss";

function Logo({ isMask, className = "" }) {
  return (
    <h2 className={`${styles["app-text-logo"]} ${className}`}>
      <a
        href="/"
        className={`${styles["app-text-logo__content"]} ${
          isMask ? styles["mask"] : ""
        }`}
      >
        CYNOCHIC
      </a>
    </h2>
  );
}

export default Logo;
