import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getBucketPath } from "../../common/utils";
import HomeSection from "../../component/HomeSection";
import HomePeoductList from "../../component/HomePeoductList";

const defaultList = [
  [
    223,
    "Mini Dioriviera Lady Dior Bag",
    `https://cynsrchic.com/bucket/Women'Bags%20DIOR/Mini%20Dioriviera%20Lady%20Dior%20BagM0505OIFI_M51E/1717166717_M0505OIFI_M51E_E01_GHC.jpg`,
    "520",
  ],
  [
    222,
    "Mini Dioriviera Lady Dior Bag",
    `https://cynsrchic.com/bucket/Women'Bags%20DIOR/Mini%20Dioriviera%20Lady%20Dior%20BagM0505OIFI_M03I/1717519974_M0505OIFI_M03I_E01_GHC.jpg`,
    "520",
  ],
  [
    230,
    "Mini Lady Dior Bag",
    `https://cynsrchic.com/bucket/Women'Bags%20DIOR/Mini%20Lady%20Dior%20BagM0505OWCB_M030/1637000729_M0505OWCB_M030_E01_GHC.jpg`,
    "550",
  ],
  [
    232,
    "Mini Lady Dior Bag",
    `https://cynsrchic.com/bucket/Women'Bags%20DIOR/Mini%20Lady%20Dior%20BagM0505SLOI_M989/1699811120_M0505SLOI_M989_E01_GHC.jpg`,
    "550",
  ],
].map(([id, name, cover, price]) => ({
  id,
  name,
  cover,
  link: "",
  price,
}));

const defaultBanner = getBucketPath("/Web/banner/2.webp");

function Recommended() {
  const [product, setProduct] = useState([]);

  const fetchData = () => {
    setProduct(defaultList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HomeSection
      titleMeta={{
        overline: "Women",
        title: "New In: Mini Lady Dior Bag",
        ctas: "Shop the Collection",
      }}
      rootClassName={styles["recommended"]}
      className={styles["recommended-wrapper"]}
    >
      <div
        className={`${styles["recommended-wrapper__cover"]} ${styles["sticky"]}`}
      >
        <img src={defaultBanner} alt="" />
      </div>
      <div
        className={`${styles["recommended-wrapper__product"]} ${styles["sticky"]}`}
      >
        <HomePeoductList list={product} />
      </div>
    </HomeSection>
  );
}

export default Recommended;
