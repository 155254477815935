import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "./Logo";
import styles from "./Footer.module.scss";

function Footer() {
  const [menuState, setMenuState] = useState(0);
  const stateString = useMemo(
    () => menuState.toString(2).padStart(4, "0"),
    [menuState]
  );

  const resizeHandle = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 16 * 64 && menuState != 0) {
      setMenuState(() => 0);
    }
  };

  const openMenu = (index) => {
    // 允许打开多个
    // const menus = stateString;
    // setMenuState(
    //   parseInt(
    //     `${menus.substring(0, index)}${
    //       (Number(menus[index]) + 1) % 2
    //     }${menus.substring(index + 1)}`,
    //     2
    //   )
    // );
    setMenuState((v) =>
      v > 0 && v[index] > 0 ? 0 : "1".padStart(index + 1, "0").padEnd(4, "0")
    );
  };

  const windowObserver = (isRemove = false) => {
    if (isRemove) {
      window.removeEventListener("resize", resizeHandle);
    } else {
      window.addEventListener("resize", resizeHandle);
    }
  };

  useEffect(() => {
    windowObserver();
    return () => {
      windowObserver(true);
    };
  }, []);

  return (
    <div className={styles["footer"]}>
      <div className={styles["footer-content"]}>
        <Logo className={styles["footer-content__logo"]} />
        <div className={styles["footer-content__gutters"]}>
          <ul
            className={`${styles["footer-content__gutters__list"]} ${
              stateString[3] === "0" ? styles["less"] : ""
            }`}
          >
            <li
              onClick={() => openMenu(3)}
              className={styles["footer-content__gutters__list-title"]}
            >
              <span
                className={styles["footer-content__gutters__list-title__text"]}
              >
                Help
              </span>
              <button
                className={
                  styles["footer-content__gutters__list-title__button"]
                }
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </button>
            </li>
            <li>
              Our Client Advisors are available to assist you via WhatsApp at{" "}
              <a className="underline" href="tel:+44 7507361759">
                +44 7507361759
              </a>{" "}
              , or you may also chat with us.
              {/* <a className="underline" href="#">
                发送消息
              </a>{" "}
              或 <a href="#">发电子邮件</a> */}
            </li>
            <li>
              <a href="#">FAQs</a>
            </li>
          </ul>
          <ul
            className={`${styles["footer-content__gutters__list"]} ${
              stateString[2] === "0" ? styles["less"] : ""
            }`}
          >
            <li
              onClick={() => openMenu(2)}
              className={styles["footer-content__gutters__list-title"]}
            >
              <span
                className={styles["footer-content__gutters__list-title__text"]}
              >
                Services
              </span>
              <button
                className={
                  styles["footer-content__gutters__list-title__button"]
                }
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </button>
            </li>
            <li>
              <a href="#">Repairs</a>
            </li>
            <li>
              <a href="#">Personalization</a>
            </li>
            <li>
              <a href="#">Art of Gifting</a>
            </li>
          </ul>
          <ul
            className={`${styles["footer-content__gutters__list"]} ${
              stateString[1] === "0" ? styles["less"] : ""
            }`}
          >
            <li
              onClick={() => openMenu(1)}
              className={styles["footer-content__gutters__list-title"]}
            >
              <span
                className={styles["footer-content__gutters__list-title__text"]}
              >
                Cynosure
              </span>
              <button
                className={
                  styles["footer-content__gutters__list-title__button"]
                }
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </button>
            </li>
            <li>
              <a href="#">Join Us</a>
            </li>
            <li>
              <a href="#">Sustainability</a>
            </li>
          </ul>
          <ul
            className={`${styles["footer-content__gutters__list"]} ${
              stateString[0] === "0" ? styles["less"] : ""
            }`}
          >
            <li
              onClick={() => openMenu(0)}
              className={styles["footer-content__gutters__list-title"]}
            >
              <span
                className={styles["footer-content__gutters__list-title__text"]}
              >
                Follow
              </span>
              <button
                className={
                  styles["footer-content__gutters__list-title__button"]
                }
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </button>
            </li>
            <li>
              <a className="underline" href="#">
                Sign up
              </a>{" "}
              for Cyno emails and receive the latest news from the Maison,
              including exclusive online pre-launches and new collections.
            </li>
            <li>
              <a href="https://www.tiktok.com/@cynovintage?_t=8ngtIzJzYOU&_r=1">
                tiktok
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles["footer__actions"]}>
        <ul className={styles["footer__actions-list"]}>
          <li>ship to</li>
          <li>
            <a href="#">Sitemap</a>
          </li>
          <li>
            <a href="#">Legal Notices</a>
          </li>
          <li>
            <a href="#">Privacy Policy</a>
          </li>
          <li>
            <a href="#">California Supply Chains Act</a>
          </li>
          <li>
            <a href="#">Do Not Sell or Share My Persional Information</a>
          </li>
          <li>
            <a href="#">Accessibility</a>
          </li>
        </ul>
        <Logo />
      </div>

      <section className={styles["footer__chat"]}>
        <h3 className={styles["footer__chat__button"]}>
          <FontAwesomeIcon icon="fa-solid fa-comment-dots" />
          <span>Chat with an advisor</span>
        </h3>
      </section>
    </div>
  );
}

export default Footer;
