import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageContainer from "../../component/PageContainer";
import ProductDetail from "./ProductDetail";
import NotFound from "./NotFound";
import { useGlobalState } from "Store/global";
import styles from "./Product.module.scss";

function Product() {
  let { id } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [{ fetch }] = useGlobalState();

  const fetchProductDetail = async () => {
    if (fetch) {
      setIsLoading(true);
      try {
        const res = await fetch.get(`/product/detail/${id}`);
        setProduct(res.data);
      } catch (err) {
        console.log("error:", err);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetail();
  }, []);

  return (
    <PageContainer>
      {product || isLoading ? (
        <ProductDetail product={product} />
      ) : (
        <NotFound />
      )}
    </PageContainer>
  );
}

export default Product;
