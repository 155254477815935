import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import Store from "./store/index";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import router from "./router";
import "./common/fontawsome";
import fetch from "./common/fetch";

const root = ReactDOM.createRoot(document.getElementById("root"));
window.globalHelp = {
  fetch,
};

root.render(
  <>
    {/* <React.StrictMode> */}
    <Store>
      <RouterProvider router={router} />
    </Store>
    {/* </React.StrictMode> */}
  </>
);

reportWebVitals();
