import {
  faMagnifyingGlass,
  faBars,
  faBagShopping,
  faPlus,
  faCommentDots,
  faXmark,
  faChevronRight,
  faCircleXmark,
  faCircleCheck,
  faAngleLeft,
  faAngleRight,
  faSpinner,
  faHeart as SolidHeart,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart,
  faUser,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import {} from "@fortawesome/free-brands-svg-icons";

const icons = [
  faMagnifyingGlass,
  faUser,
  SolidHeart,
  faHeart,
  faBars,
  faBagShopping,
  faPlus,
  faCommentDots,
  faChevronRight,
  faXmark,
  faCircleXmark,
  faCircleCheck,
  faAngleLeft,
  faAngleRight,
  faSpinner,
  faEye,
  faEyeSlash,
];

export default icons;
