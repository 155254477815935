import React from "react";
import styles from "./Breadcrumb.module.scss";

const Breadcrumb = ({ children }) => {
  return <ul className={styles["breadcrumb"]}>{children}</ul>;
};

const BreadcrumbItem = ({ children }) => {
  return (
    <li className={styles["breadcrumb-item"]}>
      <div className={styles["breadcrumb-item__content"]}>{children}</div>
      <span className={styles["breadcrumb-item__separator"]}>/</span>
    </li>
  );
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
