import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageContainer from "../../component/PageContainer";
import styles from "./ProductDetail.module.scss";

function ProductDetail({ product }) {
  return (
    <div className={styles["product-detail"]}>
      <div
        className={[
          styles["product-detail__pannel"],
          styles["product-detail__preview"],
        ].join(" ")}
      >
        <ul className={styles["product-detail__preview-list"]}>
          {(product?.picktures ?? []).map((pickture, index) => (
            <li
              className={styles["product-detail__preview-list__item"]}
              key={index.toString()}
            >
              <img src={pickture} alt="" />
            </li>
          ))}
        </ul>
      </div>
      <div
        className={[
          styles["product-detail__pannel"],
          styles["product-detail__info"],
        ].join(" ")}
      >
        <div className={styles["product-detail__info-container"]}>
          <div className={styles["info-head"]}>
            <p className={styles["info-head__model"]}>{product?.model}</p>
            <p className={styles["info-head__tag"]}>New</p>
            <h1 className={styles["info-head__product-name"]}>
              {product?.name}
            </h1>
            <button className={styles["info-head__favorite"]}>
              <FontAwesomeIcon icon="fa-regular fa-heart" />
            </button>
          </div>
          <div className={styles["info-price"]}>
            <span>${product?.price}</span>
          </div>
          {/* <button className={styles["contact-us"]}>Contact Us</button> */}
          <a
            href={process.env.REACT_APP_WHATSAPP_URL}
            target="_blank"
            className={styles["contact-us"]}
          >
            Contact Us
          </a>
          <div className={styles["info-desc"]}>
            <span>
              {product?.description ??
                "Complimentary Carbon Efficient Delivery or Collect-in-Store."}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
