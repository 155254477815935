import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useUserState } from "Store/user";
import { useGlobalState } from "Store/global";
import Logo from "./Logo";
import SliderBar from "./SliderBar";
import styles from "./NavBar.module.scss";
import { getToken } from "@/common/fetch";

function NavBar() {
  // todo 移出page-container
  const [isOpenSlider, setIsOpenSlider] = useState(false);
  const [storeUser, setStoreUser] = useUserState();
  const [{ fetch }] = useGlobalState();

  const getUserInfo = async () => {
    const token = getToken();
    let user = null;
    if (token) {
      const res = await fetch.get("/user/userInfo");
      if (res?.success) {
        user = res.data;
      } else {
        console.log(res);
      }
    } else {
    }
    setStoreUser({
      loading: false,
      user,
    });
  };

  const triggerSlider = () => {
    setIsOpenSlider((v) => !v);
  };

  useEffect(() => {
    if (!storeUser?.init) {
      getUserInfo();
    }
  }, []);

  return (
    <>
      <div className={styles["nav"]}>
        <header className={styles["nav-warpper"]}>
          <button className={styles["menu-button"]} onClick={triggerSlider}>
            <FontAwesomeIcon
              className={styles["icon"]}
              icon={`fa-solid fa-${isOpenSlider ? "xmark" : "bars"}`}
            />
            <span>{isOpenSlider ? "Close" : "Menu"}</span>
          </button>
          <SliderBar isOpen={isOpenSlider} setIsOpen={setIsOpenSlider} />
          <ul className={styles["tools"]}>
            {/* <li className={styles["tools-item"]}>
              <button className={styles["tools-item__button"]}>
                <FontAwesomeIcon
                  className={styles["icon"]}
                  icon="fa-solid fa-bars"
                />
                <span>Menu</span>
              </button>
            </li> */}
            <li className={`${styles["tools-item"]} ${styles["search"]}`}>
              <button className={styles["tools-item__button"]}>
                <FontAwesomeIcon
                  className={styles["icon"]}
                  icon="fa-solid fa-magnifying-glass"
                />
                <span>Search</span>
              </button>
            </li>
            <li className={styles["tools-item"]}>
              <button className={styles["tools-item__button"]}>
                <span>Contact Us</span>
              </button>
            </li>
            <li className={`${styles["tools-item"]} ${styles["collsition"]}`}>
              <Link
                to="/profile/favorite"
                className={styles["tools-item__button"]}
              >
                <FontAwesomeIcon
                  className={styles["icon"]}
                  icon="fa-regular fa-heart"
                />
              </Link>
            </li>
            <li className={styles["tools-item"]}>
              <Link to="/profile" className={styles["tools-item__button"]}>
                <FontAwesomeIcon
                  className={styles["icon"]}
                  icon="fa-regular fa-user"
                />
              </Link>
            </li>
            {/* <li className={styles["tools-item"]}>
              <button className={styles["tools-item__button"]}>
                <FontAwesomeIcon
                  className={styles["icon"]}
                  icon="fa-solid fa-bag-shopping"
                />
              </button>
            </li> */}
          </ul>
          <Logo className={styles["logo"]} isMask />
        </header>
        {/* <div className={styles["search-bar"]}>
          <div className={styles["search-bar__warpper"]}>
            <input type="text" className={styles["search-bar__warpper__input"]} />
          </div>
        </div> */}
      </div>
    </>
  );
}

export default NavBar;
