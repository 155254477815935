import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import LoadingMask from "./LoadingMask";
import { useGlobalState } from "Store/global";
import PageContent from "./PageContent";
import AlertContainer from './AlertContainer'

const AppPage = () => {
  const [_, setGlobal] = useGlobalState();
  const [isDisplayMask, setIsDisplayMask] = useState(false);

  useEffect(() => {
    setGlobal((v) => ({
      ...v,
      triggleLoadingMask: () => setIsDisplayMask((v) => !v),
    }));
  }, []);

  return (
    <>
      <AlertContainer />
      <NavBar />
      <PageContent />
      <Footer />
      {isDisplayMask && <LoadingMask />}
    </>
  );
};

export default AppPage;
