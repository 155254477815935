import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getBucketPath } from "../../common/utils";
import HomeSection from "../../component/HomeSection";
import HomeCategoryList from "../../component/HomeCategoryList";

const defaultList = [
  ["Services", ["Contact Us"]],
  ["Art of Gifting", ["Gifts for Women", "Gifts for Men"]],
  ["Best Sellers", ["Explore"]],
].map(([name, buttons], index) => ({
  id: index.toString(),
  name,
  cover: getBucketPath(`/Web/services/${index + 1}.webp`),
  link: "",
  buttons: buttons.map((n) => ({ name: n, link: "" })),
}));

function RecommendedCufflinks() {
  const [product, setProduct] = useState([]);

  const fetchData = () => {
    setProduct(defaultList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HomeSection
      titleMeta={{
        title: "Cynosure Services",
        ctas: "All orders are carefully packaged in the iconic boxes",
      }}
      rootClassName={styles["serverice"]}
      className={styles["serverice-wrapper"]}
    >
      <div className={styles["serverice-wrapper__category"]}>
        <HomeCategoryList
          list={product}
          className={styles["serverice-wrapper__category-list"]}
        />
      </div>
    </HomeSection>
  );
}

export default RecommendedCufflinks;
