import { atom, useSetRecoilState, useRecoilValue } from "recoil";
import fetch from "@/common/fetch";

export const globalState = atom({
  key: "globalState",
  default: {
    fetch,
  },
});

export const useGlobalState = () => [
  useRecoilValue(globalState),
  useSetRecoilState(globalState),
];

export const useGlobalTool = (key) => useRecoilValue(globalState)[key];
