import React from "react";
import styles from "./PageContainer.module.scss";

function PageContainer({ children, className = "", ...other }) {
  return (
    <div className={`${styles["page"]} ${className}`} {...other}>
      {children}
    </div>
  );
}

export default PageContainer;
