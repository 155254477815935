import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import styles from "./HomeCategoryList.module.scss";
import { Link } from "react-router-dom";

function HomeCategoryList ({ list = [], className = "" }) {
  return (
    <ul className={`${styles["home-category__list"]} ${className}`}>
      {list.map((item) => (
        <li key={item.id} className={styles["home-category__list-item"]}>
          <div className={styles["home-category__list-item__cover"]}>
            <img src={item.cover} />
          </div>
          <div className={styles["home-category__list-item__name"]}>
            <Link to={item?.link ?? '#'}>{item.name}</Link>
          </div>

          {/* Home Page Services Button */}
          {item?.buttons?.length > 0 ? (
            <div className={styles["home-category__list-item__buttons"]}>
              {(item?.buttons ?? []).map((button, index) => (
                <Link to={button.link} key={index.toString()}>
                  {button.name}
                </Link>
              ))}
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
}

export default HomeCategoryList;
